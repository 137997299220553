// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

.error-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  position: relative;
  @include media("<=480px") {
    flex-direction: column;
    margin-top: 2.5rem;
  }
  .error-bear-video {
    width: 350px;
    @include media("<=480px") {
      width: 300px;
      position: relative;
      left: -2rem;
    }
  }
  .speech-bubble {
    width: 600px;
    position: absolute;
    transform: translateX(15px);
    top: 0;
    @include media("<=480px") {
      left: 0;
      transform: translateX(0);
    }
  }
  .gotohome-button {
    font-family: $font-superStar;
    text-align: center;
    position: relative;
    font-size: 22px;
    cursor: $cursor-Pointer;
    color: #f27921;
    clip-path: $pixelBorderRadius;
    padding: 0.4rem 1rem;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    background-color: #202020;
    @include media("<=480px") {
      top: 2rem;
    }
    &:hover {
      background-color: #151515;
    }
  }
}
