// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;

canvas {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 0;
}
// Win popup back
.winpopup-back {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.winpopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 14.5rem;
  background-color: #202020;
  color: white;
  display: flex;
  flex-direction: column;
  opacity: 0;
  gap: 1rem;
  border-radius: 20px;
  border: 12.5px solid transparent;
  border-image: url("../../assets/media/icons/LoginBorder.png") 25 stretch;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .congrats-message {
    font-size: 32px;
    text-align: center;
    line-height: normal;
    opacity: 0;
  }
  .okay-button {
    font-size: 24px;
    font-family: $font-superStar;
    background-color: #303030;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    opacity: 0;
    &:hover {
      cursor: $cursor-Pointer;
      background-color: #404040;
    }
  }
}
