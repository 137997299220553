button {
  font-size: 20px;
  margin: 4px;
}

input {
  font-size: 20px;
  margin: 4px;
}

a {
  color: white;
  padding: 4px;
  font-size: 24px;
}
