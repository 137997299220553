// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

// ! FAQ modal
.faqmodal-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.52);
  z-index: 5;
}

.faqmodal {
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  opacity: 0;
  width: 0;
  height: 45rem;
  background-color: #202020;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.25rem 1rem 2rem 1rem;
  border-radius: 20px;
  border: 12.5px solid transparent;
  border-image: url("../../assets/media/icons/LoginBorder.png") 25 stretch;
  @include media("<=480px") {
    width: 100%;
  }
  .faqmodal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 36px;
    color: rgb(151, 151, 151);
    font-family: $font-superStar;
    opacity: 0;
    .faqmodal-header-close {
      cursor: $cursor-Pointer;
      color: white;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      clip-path: $pixelBorderRadius;
      &:hover {
        background-color: #646464;
      }
      svg {
        path {
          fill: rgb(151, 151, 151);
        }
      }
    }
  }
  .faqmodal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    line-height: normal;
    font-size: 26px;
    .question {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .faqmodal-content-title {
        color: rgb(151, 151, 151);
        font-family: $font-superStar;
      }
      p {
        font-family: $font-pixellari;
        font-weight: 400;
        font-size: 20px;
        text-indent: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: relative;
  margin-bottom: 2.75rem;
  @include media("<=889px") {
    padding: 0 0.5rem;
    position: absolute;
    transform: translateY(-115px);
  }
  .header-left-side {
    position: relative;
    top: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #ffa556;
    font-size: 24px;
    cursor: $cursor-Pointer;
    font-family: $font-pixellari;
    &:hover {
      text-decoration: underline;
    }
    .trading-rules {
      position: relative;
      top: 0.1rem;
      @include media("<=480px") {
        font-weight: 100;
        font-size: 16px;
      }
    }
    @include media("<=480px") {
      font-size: 20px;
      position: relative;
      gap: 0.25rem;
      font-weight: bolder;
    }
  }
  .header-middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    font-family: $font-superStar;
    @include media("<=889px") {
      gap: 0.25rem;
    }
    @include media("<=480px") {
      left: 50%;
      transform: translateX(-50%);
    }
    a {
      cursor: $cursor-Pointer;
      color: #dedede;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      @include media("<=889px") {
        font-size: 18px;
      }
      &:hover {
        color: #ffffff;
      }
      &.active {
        color: #ffa556;
        border-bottom: #ffa556 4px solid;
      }
    }
  }
  .header-right-side {
    display: flex;
    gap: 2rem;
    align-items: center;
    .portfolio {
      display: flex;
      gap: 0.5rem;
      color: white;
      font-family: $font-superStar;
      font-size: 22px;
      @include media("<=889px") {
        display: none;
      }
      .eth {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .eth-count-full {
          position: absolute;
          padding: 0.5rem 1.5rem;
          background-color: #202020a6;
          clip-path: $pixelBorderRadius;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          z-index: 5;
          top: -3.5rem;
          display: none;
          user-select: none;
          pointer-events: none;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.show {
            display: flex;
          }
        }
        .eth-count {
          padding: 0.5rem 1rem;
          background-color: #202020;
          clip-path: $pixelBorderRadius;
          display: flex;
          align-items: center;
          gap: 0.2rem;
        }
      }
      .usd-count {
        padding: 0.5rem 1rem;
        clip-path: $pixelBorderRadius;
        background-color: #202020;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        img {
          width: 15px;
        }
      }
    }
  }
}
