// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;
$pixelBorderRadius: polygon(
  0px 8px,
  2px 8px,
  2px 4px,
  4px 4px,
  4px 2px,
  6px 2px,
  8px 2px,
  8px 0px,
  calc(100% - 8px) 0px,
  calc(100% - 8px) 2px,
  calc(100% - 4px) 2px,
  calc(100% - 4px) 4px,
  calc(100% - 2px) 4px,
  calc(100% - 2px) 6px,
  calc(100% - 2px) 8px,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 2px) calc(100% - 8px),
  calc(100% - 2px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 4px),
  calc(100% - 4px) calc(100% - 2px),
  calc(100% - 6px) calc(100% - 2px),
  calc(100% - 8px) calc(100% - 2px),
  calc(100% - 8px) 100%,
  8px 100%,
  8px calc(100% - 2px),
  4px calc(100% - 2px),
  4px calc(100% - 4px),
  2px calc(100% - 4px),
  2px calc(100% - 6px),
  2px calc(100% - 8px),
  0px calc(100% - 8px)
);

// ! MOBILE NAV SIDE
.mobile-side-menu {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(58, 58, 58, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transform: translateX(100%);
  transition: 0.5s cubic-bezier(0.25, 0.13, 0.31, 0.85);
  &.active {
    transform: translateX(0);
    display: flex;
    transition: 0.5s cubic-bezier(0.25, 0.13, 0.31, 0.85);
  }
  @include media("<=889px") {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  a {
    font-size: 36px;
  }
  .close-btn-wrapper {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 50px;
    align-items: center;
    padding: 2rem;
    .close-btn {
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      clip-path: $pixelBorderRadius;
      background-color: #8a8a8a;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.navbar {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: #0c0f12;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  .navbar-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 3rem;
    border-bottom: 2px solid #3e3e3e;
    box-shadow: 0px 3.5px 2.2px rgba(0, 0, 0, 0.07),
      0px 8.4px 5.3px rgba(0, 0, 0, 0.101), 0px 15.8px 10px rgba(0, 0, 0, 0.125),
      0px 28.1px 17.9px rgba(0, 0, 0, 0.149),
      0px 52.6px 33.4px rgba(0, 0, 0, 0.18), 0px 126px 80px rgba(0, 0, 0, 0.25);
    @include media("<=889px") {
      padding: 0.75rem 1rem;
    }
    .nav-left-side {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      img {
        cursor: $cursor-Pointer;
        width: 80px;
        @include media("<=889px") {
          position: relative;
        }
      }
      a {
        cursor: $cursor-Pointer;
        text-decoration: none;
        color: #dedede;
        font-family: $font-superStar;
        font-size: 24px;
        transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          color: #fff;
        }
        &.active {
          color: #ffa556;
        }
        @include media("<=889px") {
          display: none;
        }
        &:first-child {
          display: flex;
        }
      }
    }
    .nav-right-side {
      display: flex;
      margin-right: 1rem;
      gap: 1.25rem;
      @include media("<=480px") {
        gap: 0.5rem;
        margin: 0;
      }
      .mobile-side {
        display: none;
        width: 50px;
        height: 50px;
        background-color: rgba(140, 140, 140, 0.604);
        clip-path: $pixelBorderRadius;
        transform: translateX(25px);
        @include media("<=889px") {
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateX(0);
        }
        svg {
          width: 32.5px;
          height: 32.5px;
          path {
            fill: #ddd;
          }
        }
      }
      .bearcoin-container {
        display: flex;
        align-items: center;
        //   background-color: red;
        width: 7.5rem;
        border: 2px solid #3e3e3e;
        border-radius: 7.5px;
        .bear-coin-wrapper {
          padding: 0.25rem;
          border-right: 2px solid #3e3e3e;
          width: 35%;
        }
      }
      .wallet-container {
        display: flex;
        align-items: center;
        width: 9rem;
        border: 2px solid #3e3e3e;
        border-radius: 7.5px;
        .wallet-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30%;
          border-right: 2px solid #3e3e3e;
          // padding: 0.25rem;
          height: 100%;
        }
        .bearcoin-value-wrapper {
          width: 70%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding: 0.25rem;
          .wallet-value {
            color: #ffffff80;
            font-family: $font-superStar;
            text-align: center;
            font-size: 22px;
          }
          img {
            width: 15px;
          }
        }
      }
    }
  }
  .navbar-bottom {
    width: 100vw;
    height: 25rem;
    background-image: url("../../assets/media/images/NavBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;
    @include media("<=480px") {
      height: 17.5rem;
    }
    .allowlist-game {
      font-family: $font-superStar;
      color: #ffb137;
      font-size: 18px;
      z-index: 0;
    }
    .eth-simulator {
      font-family: $font-superStar;
      font-style: normal;
      font-weight: 400;
      font-size: 82px;
      line-height: 66px;
      z-index: 0;
      color: #ffffff;
      text-shadow: 0px 8px 0px #000000;
      @include media("<=480px") {
        font-size: 50px;
        line-height: 40px;
      }
    }
    .leaderboard-title-header {
      font-family: $font-superStar;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      z-index: 0;
      color: #ffffff;
      text-shadow: 0px 4px 0px #000000;
      @include media("<=480px") {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .user-table-info-wrapper {
      position: absolute;
      z-index: 0;
      bottom: 0;
      width: 100%;
      padding: 2.5rem;
      @include media("<=889px") {
        padding: 0;
      }
      .user-table-info-highlight {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.148);
        height: 4.5rem;
        width: 67%;
        filter: blur(10px);
        @include media("<=889px") {
          width: 100%;
          z-index: -1;
        }
      }
      .user-table-info {
        clip-path: $pixelBorderRadius;
        width: 70%;
        background-color: pink;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #222222;
        padding: 0.2rem 2rem;
        font-family: $font-superStar;
        font-size: 24px;
        color: white;
        @include media("<=889px") {
          width: 100%;
          padding: 0.2rem 1rem;
          clip-path: none;
          z-index: 2;
        }
        .user-table-left {
          display: flex;
          flex-direction: column;
          position: relative;
          top: -0.5rem;
          @include media("<=480px") {
            top: -0.25rem;
          }
          .user-table-left-top {
            font-size: 20px;
            color: #ffffff50;
            position: relative;
            top: 0.5rem;
          }
          .user-table-left-bottom {
            display: flex;
            gap: 2rem;
            color: white;
          }
        }
        .user-table-right {
          display: flex;
          flex-direction: column;
          position: relative;
          top: 0.5rem;
          @include media("<=480px") {
            top: 0.65rem;
          }
          .user-table-right-top {
            font-size: 20px;
            color: #ffffff50;
            position: absolute;
            top: -1.5rem;
            right: 0;
            width: 2.5rem;
            display: flex;
            justify-content: space-between;
          }
          .user-table-right-bottom {
            display: flex;
            gap: 2rem;
            color: white;
          }
        }
      }
    }

    .navbar-bottom-gradient {
      position: absolute;
      bottom: -1px;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #261412 100%);
    }
  }
}

.login-button {
  text-align: center;
  position: relative;
  font-family: $font-superStar;
  font-size: 22px;
  cursor: $cursor-Pointer;
  color: #f27921;
  clip-path: $pixelBorderRadius;
  padding: 0.4rem 1rem;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: #202020;
  @include media("<=480px") {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }
  &:hover .user {
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }
  .logout {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    opacity: 0;
    user-select: none;
    pointer-events: none;
    color: #f27921;
  }
  &:hover .logout {
    opacity: 1;
    user-select: auto;
    pointer-events: auto;
  }
  .user {
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    background-color: #151515;
  }
}
