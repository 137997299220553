// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;

.top-canditate-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 0;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-bottom: 1px solid #1b1b1a;

  &:hover {
    background-color: #353535;
  }

  .top-candidate-left-side {
    display: flex;
    align-items: center;
    gap: 4rem;
    @include media("<=480px") {
      gap: 1.5rem;
    }
    .top-rank {
      width: 5rem;
      border-radius: 0 4px 4px 0;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 0.6rem 0 0;
      gap: 0.25rem;
      font-weight: 1000;
      color: rgba(214, 214, 214, 1);
      @include media("<=480px") {
        width: 3.5rem;
      }
      &.one {
        .top-rank-number {
          margin-left: 0.55rem;
        }
        color: #fff;
        font-size: 36px;
        background-color: #cbb765;
        @include media("<=480px") {
          font-size: 28px;
        }
      }
      &.two {
        color: #fff;
        font-size: 36px;
        background-color: #8e91a7;
        @include media("<=480px") {
          font-size: 28px;
        }
      }
      &.three {
        color: #fff;
        font-size: 36px;
        background-color: #a86243;
        @include media("<=480px") {
          font-size: 28px;
        }
      }
      img {
        width: 25px;
        height: 25px;
        @include media("<=480px") {
          width: 15px;
          height: 15px;
        }
      }
      .top-wallet-address {
        font-size: 18px;
      }
    }
  }
  .top-canditate-right-side {
    font-size: 34px;
    span {
      color: white;
      &.red {
        color: #ef4444;
      }
      &.green {
        color: #4cf752;
      }
    }
  }
}

.no-result {
  font-size: 32px;
  color: #fff;
  padding-left: 2rem;
  @include media("<=480px") {
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    color: #ddd;
    font-size: 24px;
  }
}
.weekly-candidate-right-side {
  font-size: 34px;
  display: flex;
  width: 7rem;
  max-width: 10rem;
  justify-content: space-between;
  @include media("<=480px") {
    font-size: 24px;
    justify-content: flex-end;
  }
  .weekly-candidate-lostgainedamount {
    .rate-lost-raise {
      color: #fff;
      &.red {
        color: #ef4444;
      }
      &.green {
        color: #4cf752;
      }
      &.white {
        color: #fff;
      }
    }
  }
}
