// Media Queries:
// 1600px for Intro
// 889px

// Include media for media queries
@import "/node_modules/include-media/dist/include-media";

$font-superStar: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
$font-pixellari: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
$cursor-Pointer: url("../../assets/media/Cursors/cursorPointer.png"), pointer;

/* Fonts */
@font-face {
  font-family: "Superstar";
  src: url("./assets/fonts/Superstar.woff2") format("woff2"),
    url("./assets/fonts/Superstar.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Pixellari";
  src: url("./assets/fonts/Pixellari.woff2") format("woff2"),
    url("./assets/fonts/Pixellari.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Root */
:root {
  --onboard-font-family-normal: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
  --onboard-font-family-semibold: "Pixellari", Verdana, Geneva, Tahoma,
    sans-serif;
  --onboard-font-family-light: "Pixellari", Verdana, Geneva, Tahoma, sans-serif;
  --onboard-font-size-1: 3rem;
  --onboard-font-size-2: 2.25rem;
  --onboard-font-size-3: 1.5rem;
  --onboard-font-size-4: 1.25rem;
  --onboard-font-size-5: 1.3rem;
  --onboard-font-size-6: 1.1rem;
  --onboard-font-size-7: 0.75rem;
  --onboard-close-button-background: #858585;
  --onboard-modal-color: #ddd;
  --onboard-modal-background: #222222;
  --onboard-wallet-button-border-radius: 5px;
  --onboard-wallet-button-background-hover: #858585;
  --onboard-connect-sidebar-progress-background: #858585;
  --onboard-connect-sidebar-progress-color: #fff;
  --onboard-modal-border-radius: 5px;
  --onboard-wallet-columns: 1;
  --account-center-minimized-background: #222222;
  --account-center-minimized-address-color: #fff;
  --account-center-maximized-address-color: #fff;
  --account-center-minimized-chain-select-background: #727272;
  --account-center-network-selector-color: #fff;
  --account-center-border-radius: 5px;
  --account-center-minimized-balance-color: #acacac;
  --account-center-border: #585858;
  /* CUSTOMIZE SECTIONS OF THE CONNECT MODAL */
  // $onboard-connect-content-width: 2rem !important;
  // $onboard-connect-content-height: ;
  // $onboard-wallet-columns: ;
  // $onboard-connect-sidebar-background: ;
  // $onboard-connect-sidebar-color: ;
  // $onboard-connect-sidebar-progress-background: ;
  // $onboard-connect-sidebar-progress-color: ;
  // $onboard-connect-header-background: ;
  // $onboard-connect-header-color: ;
  // $onboard-main-scroll-container-background: ;
  // $onboard-link-color: ;
  // $onboard-close-button-background: ;
  // $onboard-close-button-color: ;
  // $onboard-checkbox-background: ;
  // $onboard-checkbox-color: ;
  // $onboard-wallet-button-background: ;
  // $onboard-wallet-button-background-hover: ;
  // $onboard-wallet-button-color: ;
  // $onboard-wallet-button-border-color: ;
  // $onboard-wallet-button-border-radius: ;
  // $onboard-wallet-button-box-shadow: ;
  // $onboard-wallet-app-icon-border-color: ;
}

html {
  cursor: url(./assets/media/Cursors/cursorDefault.png), auto;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-pixellari;
  overflow-x: hidden !important;
  background-color: #261412;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none;
}

.toast-success,
.toast-error {
  font-family: $font-pixellari !important;
}

// Modal styling
